import { useCallback, useState } from 'react'

export type statusType = 'idle' | 'loading' | 'success' | 'error'

export function useAsync<T, E = string>() {
  const [status, setStatus] = useState<statusType>('idle')
  const [data, setValue] = useState<T | null>(null)
  const [error, setError] = useState<E | null>(null)

  const run = useCallback((promiseObject: Promise<T | null>) => {
    setStatus('loading')
    setValue(null)
    setError(null)

    return promiseObject
      .then((response: any) => {
        setValue(response)
        setStatus('success')
      })
      .catch((error: any) => {
        setError(error)
        setStatus('error')
      })
  }, [])

  const reset = useCallback(() => {
    setStatus('idle')
    setError(null)
  }, [])

  return { run, reset, status, data, error }
}

import axios from 'axios'
import { useSession } from 'next-auth/react'
import { Session } from 'types/auth'

import { BCKS_SESSION_STORAGE_KEY } from 'configs/auth'

const EVENT_NAME = 'guru_ksps_fe_user_action_v2'

interface TrackerParams {
  event_name: string
  event_version?: number
  user_agent?: string
}

const getDefaultData = () => {
  const bcksUserSession: Session = JSON.parse(
    window.localStorage.getItem(BCKS_SESSION_STORAGE_KEY)
  )

  return {
    id: crypto.randomUUID(),
    user_id: bcksUserSession?.user?.id,
    session_id: bcksUserSession?.sessionId,
    timestamp: new Date().toISOString(),
    email: bcksUserSession?.user?.email,
    event_version: 2,
  }
}

export const useTracker = () => {
  const session = useSession()

  const satuEventTrack = (params?: TrackerParams) => {
    const requestBody = {
      event_name: EVENT_NAME,
      data: JSON.stringify({
        ...getDefaultData(),
        ...params,
      }),
    }

    if (!!session.data?.satuEventUrl) {
      axios
        .post(session.data.satuEventUrl, requestBody, {
          headers: {
            'Content-Type': 'application/json',
          },
          auth: {
            username: session.data.satuEventUsername as string,
            password: session.data.satuEventPassword as string,
          },
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }

  return {
    satuEventTrack,
  }
}
